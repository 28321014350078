<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :onExport="onExport">
    <el-form
      :model="form"
      :rules="rules"
      ref="logFilterForm"
      label-width="70px"
      size="medium"
      class="filter-from"
      @submit.native.prevent
    >
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="投递人：" prop="nickname">
          <el-input v-model="form.nickname" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="岗位类型：" prop="category_id">
          <el-select v-model="form.career_category_name" placeholder="请选择 ">
            <el-option
              v-for="(item, i) in filter.career_category_name"
              :key="i"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="意向岗位：" prop="intention_career">
          <el-select v-model="form.intention_career" placeholder="请选择 ">
            <el-option
              v-for="(item, i) in filter.intention_career"
              :key="i"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="求职状态：" prop="work_status">
          <el-select v-model="form.work_status" placeholder="请选择 ">
            <el-option
              v-for="(item, i) in filter.work_status"
              :key="i"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from '@/base/layout/LayoutFilter'
import DatePeriodPicker from '@/base/components/Form/DatePeriodPicker'
import { getCategories } from '@/modules/employ/api/employ-career-list'
import {
  exportDeliver,
  getFilters,
} from '@/modules/employ/api/employ-deliver-user-list'
import { categoryList } from '@/modules/employ/api/employ-career-category-list'
export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    ids: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      rules: {},
      categoryIdArr: [],
      companyCategoryArr: [],
      resumeCategoryArr: [],
      statusArr: [
        { name: '全部', id: -1 },
        { name: '未投递', id: 0 },
        { name: '已投递', id: 1 },
        { name: '投递失败', id: 2 },
      ],
      filter: {
        intention_career: [],
        career_category_name: [],
        work_status: [],
      },
    }
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs['logFilterForm'].resetFields()
      this.uploadFilter(this.form)
    },

    //筛选
    ok() {
      this.uploadFilter(this.form)
    },

    // 导出列表
    onExport() {
      const postData = { ...this.form, export_ids: this.ids, page: this.page }
      exportDeliver(postData).catch((err) => {})
    },
  },
  created() {
    getCategories().then((res) => {
      this.companyCategoryArr = [...res.data]
    })
    categoryList().then((res) => {
      this.categoryIdArr = [{ name: '全部', id: -1 }, ...res.data.list]
    })
    getFilters().then((res) => {
      this.filter = res.data
    })
  },
  computed: {
    form: {
      get() {
        return this.filterForm
      },
      set(val) {},
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
}
</script>
