import api from '@/base/utils/request'
import fetchFile from '@/base/utils/download'

export const getDeliverList = (data) => {
  return api({
    url: '/admin/employ/deliverUser/index',
    method: 'post',
    data,
  })
}
export const getFilters = (data) => {
  return api({
    url: '/admin/employ/deliverUser/filters',
    method: 'post',
    data,
  })
}

export const exportDeliver = (data) => {
  return fetchFile({
    url: '/admin/employ/deliverUser/exportIndex',
    method: 'post',
    data,
    download: true,
  })
}


