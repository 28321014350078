<template>
  <div class="page">
    <list-layout
      ref="employCareerListRefs"
      :filter-form="form"
      :thead="thead"
      :on-fetch="getList"
      @command="onCommand"
    >
      <template #filter="{ pageData, idList }">
        <ListFilter
          :filterForm="form"
          :page="pageData.page"
          :uploadFilter="ok"
          :ids="idList"
        />
      </template>
      <template #item_status_text="{ row }">
        <span>{{ row.status_text }}</span>
      </template>
    </list-layout>
    <Pagination
      :total="pageData.total"
      :page="pageData.current_page"
      :change="changPage"
    />
  </div>
</template>

<script>
import { getDeliverList } from '@/modules/employ/api/employ-deliver-user-list'
import ListFilter from '@/modules/employ/components/employ-deliver-user-list/ListFilter.vue'
import ListLayout from '@/base/layout/ListLayout'
import Pagination from '@/base/components/Default/Pagination'

export default {
  components: {
    ListLayout,
    ListFilter,
    Pagination,
  },
  data() {
    return {
      form: {
        user: '', // 用户名称
        career: '', // 岗位名称
        create_start_time: -1,
        create_end_time: -1,
        company: '', // 企业名称
        career_category: '', // 岗位类型名称
        company_category: '', // 行业类型
        resume_category: '', // 简历类型
        status: -1, // 状态：-1 全部，0:未投递，1:已投递，2:投递失败
        page: 1,
        page_size: 20,
      },
      // 弹窗不需要显示操作列，需要重新声明覆盖掉
      thead: [
        { label: '投递人', prop: 'nickname', minWidth: 100 },
        {
          label: '投递次数',
          prop: 'delivers_count',
          minWidth: 100,
          sortable: true,
        },

        {
          label: '收藏岗位数',
          prop: 'collect_careers_count',
          minWidth: 100,
          sortable: true,
        },
        { label: '意向岗位', prop: 'intention_career', minWidth: 100 },

        { label: '岗位类型', prop: 'career_category_name', minWidth: 100 },
        { label: '求职状态', prop: 'work_status', minWidth: 100 },

        {
          label: '操作',
          type: 'operation',
          width: 100,
          operations: [
            {
              command: 'check',
              text: '查看信息',
            },
          ],
        },
      ],
      pageData: {
        page_size: 15,
        tab: 'normal',
      },
    }
  },
  methods: {
    // 新增、编辑保存成功后刷新
    onRefresh() {
      this.$refs.employCareerListRefs?.getList({ page: 1 })
    },

    // 筛选回调
    ok(e) {
      this.onRefresh()
    },
    // 拉取数据
    getList(query) {
      return getDeliverList(query)
    },
    changPage(e) {
      this.form.page_size = e.page_size
      const pageData = { ...this.form, ...e }
      this.getList(pageData)
    },
    onCommand({ command, row }) {
      switch (command) {
        case 'check':
          let routerData = this.$router.resolve({
            name: "MyResume",
            params: {
              data: this.$enCode({id: row.user_id, type: 'formal'})
            }
          })
          window.open(routerData.href, '_blank')
          break
        default:
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.panel {
  position: absolute;
  right: 60px;
  top: 110px;
}
</style>
